import React from 'react';
import {Container, Grid, Box} from '@material-ui/core';
import {parseGutenbergReact} from "../../../api/global";

const ConditionalWrapper = ({condition, wrapper, children}) =>
    condition ? wrapper(children) : children;

const GutenbergMuiGrid = (props) => {
  const {block, skipData} = props;
  let container, row, row_attr, row_class = [], cols, cols_attrs,
      col_extras = [], margin, marginUnit, mt, mr, mb, ml, unit;

  if (block?.data) {
    if (block.data?.container) {
      container = true;
    }

    if (block.data?.row) {
      row = true;
      row_attr = block.data.row.propsMUI || {};
      ({margin, marginUnit} = block.data.row);
      if (margin) {
        unit = marginUnit || 'px';
        ([mt, mr, mb, ml] = margin);
      }

      if (block.data.row.noGutters) {
        delete row_attr[0].spacing;
      }
      if (block.data.row.className) {
        row_class.push(block.data.row.className);
      }
      if (block.data.row.align && block.data.row.align != 'none') {
        row_class.push(`align${block.data.row.align}`);
      }
      row_class = row_class.filter(item => item);
    }

    if (block.data.cols?.length > 0) {
      cols = [];
      cols_attrs = [];

      block.data.cols.map((c) => {

        const att = c.attrs?.propsMUI[0] || c.propsMUI[0];
        let new_attr = {};

        cols.push(c.data);
        // Parse attributes:
        new_attr = {
          ...new_attr, ...{
            xs: (att.xsEq ? true : att.xs),
            sm: (att.smEq ? true : att.sm),
            md: (att.mdEq ? true : att.md),
            lg: (att.lgEq ? true : att.lg),
            xl: (att.xlEq ? true : att.xl),
          }
        }
        col_extras.push({backgroundColor: c.attrs.backgroundColor});
        cols_attrs.push(new_attr);
      });
    }
  }

  if ((container || row)) {
    return (
        <ConditionalWrapper condition={container} wrapper={children =>
            <Container>{children}</Container>}>
          {row &&
          <ConditionalWrapper condition={margin}
                              wrapper={children => <Box mt={`${mt}${unit}`}
                                                        mr={`${mr}${unit}`}
                                                        mb={`${mb}${unit}`}
                                                        ml={`${ml}${unit}`}>{children}</Box>}>
            <Grid container {...row_attr[0]} className={row_class.join(' ')}>
              {cols?.length > 0 && cols?.map((b, k) => {
                let color = (col_extras[k]?.backgroundColor?.length) ? col_extras[k].backgroundColor[0].color : undefined;
                return (
                    <Grid key={`col-${k}`} item {...cols_attrs[k]}
                          className={block.data?.cols[k]?.className}>
                      <Box p={2}
                           style={{backgroundColor: color, height: '100%'}}>
                        {b?.map((gb, i) => {
                          return parseGutenbergReact(`${k}-${i}`, gb[0] || gb, skipData);
                        })
                        }
                      </Box>
                    </Grid>
                )
              })
              }
            </Grid>
          </ConditionalWrapper>
          }
        </ConditionalWrapper>
    );
  }
  return null;
};

export default GutenbergMuiGrid;
